.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  font-family: sans-serif;
}

header {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spacer {
  height: 100vh; /* Espacio en blanco para simular el scroll */
}

.section {
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 80%;
}

h2 {
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
}

/* :::::::::NAVBAR::::::: Estilos para la animación */
.animated {
  will-change: transform, opacity;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  z-index: 1000; /* Asegúrate de que el z-index sea mayor que cualquier otro contenido de la página */
}

