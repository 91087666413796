/* Define la animación */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Aplica la animación al elemento que deseas mostrar */
.slide-in {
  animation: slideIn 0.5s ease-out forwards; /* forwards para mantener el estado final de la animación */
}

/* Servicios.css */
.card-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

@media (min-width: 768px) {
  .card-group {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }

  .divider {
    display: block;
  }
}

.grid {
  display: grid;
  gap: 2rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile specific adjustments */
@media (max-width: 767px) {
  .divider {
    display: none;
  }

  .card {
    margin-bottom: 1rem;
  }
}

/* Animations */
.slide-in {
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
